import { debounce } from 'lodash';
import noop from 'lodash/noop';
import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Immutable } from 'seamless-immutable';
import { Title } from '../../../../config/api/types';
import useApiRoute from '../../../../config/api/useApiRoute';
import ROUTE_KEY from '../../../../config/routes/routeKeys';
import usePath from '../../../../config/routes/usePath';
import elasticQueryDSL from '../../../../helpers/elasticQueryDSL';
import getIdFromUrl from '../../../../helpers/getIdFromUrl';
import {
  getLink,
  retrieveQueryParameter,
  updateQueryParameters,
} from '../../../../helpers/hateoas';
import useQueryParams from '../../../../helpers/useQueryParams';
import { isOwnDistributionRequired, isOwnPublicationRequired } from '../../../organisation/domain';
import security from '../../../security';
import { TITLES_PER_PAGE, getSearchQuery } from '../../domain';
import SearchAutocomplete from '../components/Search/SearchAutocomplete';
import VoidSearch from '../components/Search/VoidSearch';
import { SelectOption } from '../components/Search/types';
import { CatalogSearch, DropdownSearch, SearchFetch, SearchRenderProps } from './SearchVariants';

let waitForPrevRequestFn: (() => void) | null = null;
let callFulfilled = true;

function mapToOptions(titles?: Immutable<Title[]>): SelectOption[] {
  if (!titles) {
    return [];
  }

  // @ts-expect-error -- Error since upgrade to React 17 (react-scripts 5.0.1)
  return titles.asMutable().map((title) => ({
    label: title.title,
    value: getLink(title, 'self') || '',
    title,
  }));
}

function handleFulfil(key: string, searchFetch: SearchFetch) {
  if (key.toLowerCase().includes('search')) {
    callFulfilled = true;
    if (waitForPrevRequestFn) {
      waitForPrevRequestFn();
      waitForPrevRequestFn = null;
    }
  }
}

type Props = {
  path?: string;
  query?: string;
};

const Search = ({ path, query }: Props) => {
  const apiTitleSearchPath = useApiRoute('titles');
  const clientTitlePath = usePath(ROUTE_KEY.TITLES);
  const isCatalogPage = path === clientTitlePath;
  const userRoles = useSelector(security.selectors.getUserRole) || [];
  const currentUser = useSelector(security.selectors.getUser);
  const impersonate = useSelector(security.selectors.getImpersonate);
  const user = impersonate || currentUser;
  const isPublisher = userRoles.includes('ROLE_PUBLISHER');
  const isDistributor = userRoles.includes('ROLE_DISTRIBUTOR');
  const isMember = user?._embedded?.organisation?.hasMembership ?? false;
  const organisationId = user
    ? getIdFromUrl(user._links.organisation?.href || user._embedded.organisation?._links.self.href)
    : '';
  const isLibrary = userRoles.includes('ROLE_LIBRARY');
  const isBookstore = userRoles.includes('ROLE_BOOKSTORE');

  const { isRequired: ownDistributionRequired } = isOwnDistributionRequired({
    isPublisher,
    isDistributor,
    isMember,
  });
  const { isRequired: ownPublicationRequired } = isOwnPublicationRequired({
    isPublisher,
    isDistributor,
    isMember,
  });

  const {
    queryObj: { q },
  } = useQueryParams<{ q?: string }>(query);

  const f = useMemo(
    () =>
      elasticQueryDSL.stringify({
        ...(ownPublicationRequired
          ? {
              [`publisher.organisation.organisationId`]: organisationId,
            }
          : {}),
        ...(ownDistributionRequired
          ? {
              [`fund.organisation.organisationId`]: organisationId,
            }
          : {}),
        ...(isLibrary || isBookstore ? { hasInformationRestriction: 'false' } : {}),
      }),
    [organisationId, isBookstore, isLibrary, ownPublicationRequired, ownDistributionRequired]
  );

  if (!apiTitleSearchPath) {
    return <VoidSearch />;
  }

  const Component = isCatalogPage ? CatalogSearch : DropdownSearch;

  return (
    <Component apiTitleSearchPath={apiTitleSearchPath} onFulfil={handleFulfil}>
      {({
        searchFetch,
        dispatchSearchGet,
        initialInputValue = '',
        currentPath,
        shouldMakeCall,
        cancelRequest,
        onEmptyFocus = noop,
      }: SearchRenderProps) => {
        function handleFetchOptions(newValue: string) {
          if (!shouldMakeCall(newValue) || isCatalogPage) {
            return;
          }

          const newPath = updateQueryParameters(currentPath, {
            q: getSearchQuery(newValue),
            userInput: newValue,
            page: 1,
            limit: TITLES_PER_PAGE,
            order: retrieveQueryParameter(currentPath, 'order') || '',
            orderDir: retrieveQueryParameter(currentPath, 'orderDir') || '',
            ...(!isCatalogPage && { f }),
          });

          const dispatchNextRequest = () => dispatchSearchGet(newPath);

          if (cancelRequest) {
            cancelRequest();
            dispatchNextRequest();
            return;
          }

          if (!callFulfilled) {
            waitForPrevRequestFn = dispatchNextRequest;
          } else {
            waitForPrevRequestFn = null;
            callFulfilled = false;
            dispatchNextRequest();
          }
        }

        return (
          <SearchAutocomplete
            loadOptions={debounce(handleFetchOptions, 100)}
            options={searchFetch.value ? mapToOptions(searchFetch.value._embedded.items) : []}
            withMenu={path !== clientTitlePath}
            isLoading={searchFetch.pending}
            initialInputValue={initialInputValue}
            onFocus={onEmptyFocus}
            overrideValue={isCatalogPage ? q : undefined}
            onEnter={() => {
              waitForPrevRequestFn = null;
              callFulfilled = true;
            }}
          />
        );
      }}
    </Component>
  );
};

export default Search;

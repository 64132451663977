import { FetchConfig } from '@react-redux-fetch/core';
import { NotificationsCounters } from '../../../config/api/models/notifications';
import { NormalizedOrders, NotificationInfo, ShoppingCart } from '../../../config/api/models/shop';
import apiRoutes from '../../../config/api/routes';
import {
  AddShoppingCartLineRequest,
  UpdateShoppingCartLineRequest,
} from '../../../config/api/types';
import { Data } from '../../../config/store/types';
import { removeLineFromCart, updateCartQuantity } from './apiUtil';
import { getNumberOfProducts } from './domain';

export const getUserDepartmentRequest = (url: string): FetchConfig<Data> => {
  return {
    method: 'GET',
    requestKey: 'userDepartment',
    url: apiRoutes.create(url),
    repository: {
      userDepartment: (prev, next) => next,
    },
  };
};

export const getShoppingCartRequest = (url: string): FetchConfig<Data> => {
  return {
    method: 'GET',
    requestKey: 'shoppingCart',
    url: apiRoutes.create(url),
    repository: {
      bulkOrdersToProcess: (prev, next) => null,
      shoppingCart: (prev, next) => next,
    },
  };
};

export const getAddItemToCartRequest = (
  url: string,
  data: AddShoppingCartLineRequest,
  info: NotificationInfo
): FetchConfig<Data> => ({
  method: 'POST',
  url: apiRoutes.create(url),
  requestKey: `shoppingCart${data.titleId}`,
  fetchOptions: {
    body: data,
  },
  repository: {
    shoppingCart: (prev, next) => {
      return next;
    },
    shoppingCartAlert: () => {
      return { ...info, timestamp: new Date().getTime() };
    },
    orders: (prev: NormalizedOrders, next: ShoppingCart) => {
      if (data.orderRef) {
        // Only on the orders screen (/bestellingen), we'll pass the orderRef. So we can set the status of the order to 'forwarded'. We do this because the server can have some delay, what makes refetching directly useless.
        const newVal = { ...prev };
        const prevOrder = newVal[data.orderRef];

        if (prevOrder) {
          prevOrder.linesByDistributor.forEach((distributorLine) => {
            if (distributorLine.lines) {
              distributorLine.lines.forEach((line) => {
                if (line.id === data.orderLineReferenceId) {
                  line['stateBookstore'] = 'forwarded';
                }
              });
            }
          });
        }
        return newVal;
      }
      return { ...prev };
    },
    notificationsCounters: (prev: NotificationsCounters, next: ShoppingCart) => {
      return {
        ...prev,
        titlesInShoppingCart: getNumberOfProducts(next),
      };
    },
  },
});

export const getUpdateCartLineRequest = (
  url: string,
  data: UpdateShoppingCartLineRequest
): FetchConfig<Data> => {
  return {
    method: 'PATCH',
    url: apiRoutes.create(url),
    requestKey: url,
    fetchOptions: {
      body: data,
    },
    optimistic: {
      shoppingCart: updateCartQuantity(data, url),
    },
    repository: {
      // shoppingCart: (prev, next) => next,
    },
  };
};

export const getRemoveCartLineRequest = (url: string): FetchConfig<Data> => {
  return {
    method: 'DELETE',
    url: apiRoutes.create(url),
    optimistic: {
      shoppingCart: removeLineFromCart(url),
    },
    repository: {
      shoppingCart: (prev: ShoppingCart, next: ShoppingCart): ShoppingCart => {
        return next;
      },
      notificationsCounters: (prev: NotificationsCounters, next: ShoppingCart) => {
        return {
          ...prev,
          titlesInShoppingCart: getNumberOfProducts(next),
        };
      },
    },
  };
};
export const getConfirmOrderRequest = (url: string, data: any): FetchConfig<Data> => {
  return {
    method: 'POST',
    url: apiRoutes.create(url),
    requestKey: 'confirmOrderRequest',
    fetchOptions: {
      body: data,
    },
    repository: {
      shoppingCart: (prev: ShoppingCart, next: ShoppingCart): ShoppingCart => {
        return next;
      },
      notificationsCounters: (prev: NotificationsCounters, next: ShoppingCart) => {
        return {
          ...prev,
          titlesInShoppingCart: getNumberOfProducts(next),
        };
      },
    },
  };
};

import { FetchConfig } from '@react-redux-fetch/core';
import { useFetch } from '@react-redux-fetch/hooks';
import { useCallback, useEffect, useState } from 'react';
import { usePrevious } from '../usePrevious';
import { createExportRequest, exportStatusRequest } from './api';

export function useFileExport(
  url: string,
  withInitiation?: boolean,
  interval: number = 1000,
  onSuccess?: () => void,
  fetchConfig?: Partial<FetchConfig>,
  queryKey?: string,
  onError?: (error: string) => void
): [boolean, () => void] {
  const [startPollingWhenPostDone, setStartPollingWhenPostDone] = useState<boolean>(false);
  const [polling, setPolling] = useState<boolean>(false);
  const [exportPostRequest, createExport] = useFetch(() =>
    createExportRequest(url, fetchConfig, queryKey)
  );
  const [exportReadyRequest, getExportStatus] = useFetch(
    () => exportStatusRequest(url, queryKey, fetchConfig),
    {
      pollInterval: polling ? interval : 0,
    }
  );

  const exportReadySuccess =
    exportReadyRequest?.fulfilled && (exportReadyRequest.response as Response).status === 200;
  const exportPostDone = !exportPostRequest?.pending;
  const exportPostPending = !exportPostRequest || exportPostRequest.pending;
  const exportPostSuccess = exportPostRequest && exportPostRequest.fulfilled;
  const prevExportPostPending = usePrevious(exportPostPending);
  const prevExportReadyPending = usePrevious(exportReadyRequest?.pending);

  const exportReadyError = exportReadyRequest?.rejected && exportReadyRequest.reason.cause;

  useEffect(() => {
    if (exportReadyError && startPollingWhenPostDone) {
      setStartPollingWhenPostDone(false);
      onError?.(exportReadyError);
    }
  }, [exportReadyError, onError, startPollingWhenPostDone]);

  useEffect(() => {
    if (exportReadySuccess && exportPostDone) {
      setPolling(false);
      setStartPollingWhenPostDone(false);
    }
  }, [exportReadySuccess, exportPostDone]);

  useEffect(() => {
    if (startPollingWhenPostDone && prevExportPostPending && exportPostSuccess) {
      setPolling(true);
    }
  }, [startPollingWhenPostDone, prevExportPostPending, exportPostSuccess]);

  useEffect(() => {
    if (prevExportReadyPending && exportReadySuccess && onSuccess) {
      onSuccess();
    }
  }, [exportReadySuccess, onSuccess, prevExportReadyPending]);

  const initiateExport = useCallback(() => {
    if (polling) {
      return;
    }

    if (withInitiation) {
      createExport();
    } else {
      getExportStatus();
    }
    setStartPollingWhenPostDone(true);
  }, [createExport, getExportStatus, polling, withInitiation]);

  return [
    polling || exportPostRequest?.pending || exportReadyRequest?.pending || false,
    initiateExport,
  ];
}

export default useFileExport;

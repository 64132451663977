import fundAdmin from '../../packages/fund/admin';
import { ContactPage, FAQPage } from '../../packages/help';
import { ISBNApplicationPage, ISBNPrefixesPage, PrefixApplicationPage } from '../../packages/isbn';
import isbnAdmin from '../../packages/isbn/admin';
import ISBNBarcodePage from '../../packages/isbn/scenes/ISBNBarcodePage';
import {
  OrderByLibraryDetailPage,
  OrderDetailPage,
  OrderFileDetailPage,
  OrderFilesPage,
  OrderFromLibraryDetailPage,
  OrdersConfirmedForLibraryPage,
  OrdersConfirmedPage,
  OrdersLinesConfirmedForLibraryPage,
  OrdersLinesConfirmedPage,
  OrdersLinesProcessedForBookstorePage,
  OrdersLinesProcessedPage,
  OrdersNavLabel,
  OrdersProcessedForBookstorePage,
  OrdersProcessedPage,
  OrdersToProcessForBookstorePage,
  OrdersToProcessPage,
  QuickOrdersPage,
} from '../../packages/orders';
import OrderReplacementsPage from '../../packages/orders/scenes/OrderReplacementsPage';
import organisationAdmin from '../../packages/organisation/admin';
import bookstore from '../../packages/organisation/bookstore';
import publisher from '../../packages/organisation/publisher';
import {
  RetourDetailPage,
  RetourHistoryPage,
  RetourLinesHistoryPage,
  RetourRequestPage,
} from '../../packages/retour';
import security from '../../packages/security';
import mediaFiles from '../../packages/title/admin/mediaFiles';
import ProcessResult from '../../packages/title/admin/processResult';
import title from '../../packages/title/admin/title';
import titleFiles from '../../packages/title/admin/titleFiles';
import cart from '../../packages/title/cart';
import catalog from '../../packages/title/catalog';
import titleDetail from '../../packages/title/detail';
import { ExportTitlesPage } from '../../packages/title/export';
import ROUTE_KEY from './routeKeys';
import { NestedClientRoutes } from './types';

export const clientRoutes: NestedClientRoutes = [
  {
    name: 'Login',
    path: '/login',
    component: security.scenes.Login,
    key: ROUTE_KEY.LOGIN,
    requiredResources: ['login'],
  },
  {
    name: 'Activate',
    path: '/account/activeer',
    component: security.scenes.Activate,
    key: ROUTE_KEY.ACTIVATE,
    requiredResources: ['passwordReset'],
  },
  {
    name: 'Reset password',
    path: '/reset-password',
    component: security.scenes.Activate,
    key: ROUTE_KEY.ACTIVATE,
    requiredResources: ['passwordReset'],
  },
  {
    name: 'Forgot password',
    path: '/wachtwoord-vergeten',
    component: security.scenes.ForgotPassword,
    key: ROUTE_KEY.FORGOT_PW,
    requiredResources: ['passwordResetRequest'],
  },
  {
    name: 'Titelcatalogus',
    path: '/titels',
    component: catalog.scenes.CatalogPage,
    order: 2,
    key: ROUTE_KEY.TITLES,
    requiredResources: ['titles'],
    subRoutes: [
      {
        name: 'titel',
        path: '/titels/:slug/:id',
        key: ROUTE_KEY.TITLE,
        hideInNav: true,
        component: titleDetail.scenes.DetailPage,
      },
    ],
  },
  {
    name: 'Export titels',
    path: '/titels-export',
    component: ExportTitlesPage,
    order: 3,
    key: ROUTE_KEY.TITLES_EXPORT,
    requiredResources: ['titleExport'],
  },
  {
    name: 'Bestellen',
    path: '/bestellen',
    component: null,
    key: ROUTE_KEY.SHOPPING_CART_ORDER,
    requiredResources: ['shoppingCart'],
    subRoutes: [
      {
        name: 'Winkelmandje',
        path: '/bestellen/winkelmandje',
        component: cart.scenes.ShoppingBasket,
        key: ROUTE_KEY.SHOPPING_CART,
        requiredResources: ['shoppingCart'],
      },
      {
        name: 'Snelbestellen',
        path: '/bestellen/snelbestellen',
        component: QuickOrdersPage,
        order: 8,
        key: ROUTE_KEY.QUICK_ORDERS,
        requiredResources: ['titles'],
      },
    ],
  },
  {
    name: 'beheer titels',
    path: '/beheer-titels',
    component: null,
    order: 4,
    key: ROUTE_KEY.MANAGE_TITLES,
    subRoutes: [
      {
        name: 'titel toevoegen',
        path: '/beheer-titels/toevoegen',
        hideInNav: false,
        key: ROUTE_KEY.MANAGE_TITLE_ADD,
        requiredResources: ['titles'],
        requiredMethod: { resource: 'titles', method: 'POST' },
        component: title.scenes.AddTitlePage,
      },
      {
        name: 'titelbestanden',
        path: '/beheer-titels/titelbestanden',
        key: ROUTE_KEY.MANAGE_TITLES_UPLOAD,
        requiredResources: ['titleFiles'],
        component: titleFiles.scenes.TitleFilesPage,
        subRoutes: [
          {
            name: 'titelbestand',
            path: '/beheer-titels/titelbestand/:id',
            component: ProcessResult.scenes.ProcessResult,
            hideInNav: true,
            key: ROUTE_KEY.MANAGE_TITLES_DETAIL,
          },
        ],
      },
      {
        name: 'mediabestanden',
        path: '/beheer-titels/mediabestanden',
        key: ROUTE_KEY.MANAGE_MEDIA_UPLOAD,
        requiredResources: ['titleMediaFiles'],
        component: mediaFiles.scenes.MediaFilesPage,
      },
    ],
  },
  {
    name: 'titel toevoegen',
    path: '/beheer-titels/titel/nieuw',
    hideInNav: true,
    key: ROUTE_KEY.MANAGE_TITLE_ADD_NEW,
    requiredResources: ['titles'],
    component: title.scenes.NewTitlePage,
  },
  {
    name: 'titel',
    path: '/beheer-titels/titel/:id',
    hideInNav: true,
    key: ROUTE_KEY.MANAGE_TITLE,
    requiredResources: ['titles'],
    component: title.scenes.TitlePage,
  },
  {
    name: 'orders',
    path: '/orders',
    key: ROUTE_KEY.ORDERS,
    requiredResources: ['ordersToBeProcessed', 'orderHistoryProcessed'],
    component: OrdersToProcessPage,
    order: 6,
    badge: 'newOrders',
    navLabelComponent: OrdersNavLabel,
    subRoutes: [
      {
        name: 'historiek orders',
        path: '/orders/historiek',
        key: ROUTE_KEY.ORDERS_HISTORY_PROCESSED,
        requiredResources: ['ordersToBeProcessed', 'orderHistoryProcessed'],
        component: OrdersLinesProcessedPage,
        subRoutes: [
          {
            name: 'orders',
            path: '/orders/historiek/orders',
            hideInNav: true,
            key: ROUTE_KEY.ORDERS_HISTORY_PROCESSED_ORDERS,
            component: OrdersProcessedPage,
          },
          {
            name: 'order detail',
            path: '/order/:id',
            hideInNav: true,
            key: ROUTE_KEY.ORDER_DETAIL,
            component: OrderDetailPage,
          },
        ],
      },
    ],
  },
  {
    name: 'Orders',
    path: '/orders',
    key: ROUTE_KEY.ORDERS_HISTORY,
    requiredResources: ['orderHistory'],
    component: OrdersLinesConfirmedPage,
    subRoutes: [
      {
        name: 'orders',
        path: '/orders/orders',
        hideInNav: true,
        key: ROUTE_KEY.ORDERS_HISTORY_ORDERS,
        component: OrdersConfirmedPage,
      },
      {
        name: 'order detail',
        path: '/order/:id',
        hideInNav: true,
        key: ROUTE_KEY.ORDER_DETAIL,
        component: OrderDetailPage,
      },
    ],
  },
  {
    name: 'orderbestanden',
    path: '/orderbestanden',
    key: ROUTE_KEY.ORDER_FILES,
    requiredResources: ['orderFiles'],
    component: OrderFilesPage,
    subRoutes: [
      {
        name: 'orderbestand detail',
        path: '/orderbestanden/bestanden/:id',
        hideInNav: true,
        key: ROUTE_KEY.ORDER_FILES_DETAIL,
        component: OrderFileDetailPage,
      },
      {
        name: 'vervangingen',
        path: '/orderbestanden/vervangingen',
        hideInNav: false,
        key: ROUTE_KEY.ORDER_REPLACEMENTS,
        component: OrderReplacementsPage,
      },
    ],
  },
  {
    name: 'Bestellingen',
    path: '/bestellingen',
    key: ROUTE_KEY.ORDERS_FOR_BOOKSTORE,
    requiredResources: ['ordersToBeProcessedForBookstore'],
    badge: 'newOrders',
    navLabelComponent: OrdersNavLabel,
    component: OrdersToProcessForBookstorePage,
    subRoutes: [
      {
        name: 'Overzicht bestellingen',
        path: '/bestellingen/historiek',
        key: ROUTE_KEY.ORDERS_HISTORY_BOOKSTORE,
        requiredResources: ['ordersToBeProcessedForBookstore'],
        component: OrdersLinesProcessedForBookstorePage,
        subRoutes: [
          {
            name: 'orders',
            path: '/bestellingen/historiek/orders',
            hideInNav: true,
            key: ROUTE_KEY.ORDERS_HISTORY_BOOKSTORE_ORDERS,
            component: OrdersProcessedForBookstorePage,
          },
          {
            name: 'bestelling detail',
            path: '/bestelling/:id',
            hideInNav: true,
            key: ROUTE_KEY.ORDER_DETAIL_BOOKSTORE,
            component: OrderFromLibraryDetailPage,
          },
        ],
      },
    ],
  },
  {
    name: 'Bestellingen',
    path: '/bestellingen',
    key: ROUTE_KEY.ORDERS_HISTORY_LIBRARY,
    requiredResources: ['ordersByLibrary'],
    component: OrdersLinesConfirmedForLibraryPage,
    subRoutes: [
      {
        name: 'bestellingen',
        path: '/bestellingen/bestellingen',
        hideInNav: true,
        key: ROUTE_KEY.ORDERS_HISTORY_LIBRARY_ORDERS,
        component: OrdersConfirmedForLibraryPage,
      },
      {
        name: 'bestelling detail',
        path: '/bestelling/:id',
        hideInNav: true,
        key: ROUTE_KEY.ORDER_DETAIL,
        component: OrderByLibraryDetailPage,
      },
    ],
  },
  {
    name: 'ISBN',
    path: '/isbn',
    key: ROUTE_KEY.ISBN,
    requiredResources: ['isbnApplicationCreate'],
    component: ISBNPrefixesPage,
    subRoutes: [
      {
        name: 'ISBN Application',
        path: '/isbn/aanvragen/:isbn?',
        hideInNav: true,
        key: ROUTE_KEY.ISBN_APPLICATION,
        component: ISBNApplicationPage,
      },
      {
        name: 'Prefix Application',
        path: '/prefix/aanvragen',
        hideInNav: true,
        key: ROUTE_KEY.PREFIX_APPLICATION,
        component: PrefixApplicationPage,
      },
      {
        name: 'ISBN Barcode',
        path: '/isbn/barcode/aanmaken',
        hideInNav: true,
        key: ROUTE_KEY.BARCODE_GENERATOR,
        component: ISBNBarcodePage,
      },
    ],
  },
  {
    name: 'isbn-kantoor',
    path: '/isbn-kantoor',
    component: null,
    order: 7,
    key: ROUTE_KEY.ADMIN_ISBN,
    subRoutes: [
      {
        name: 'Isbn aanvragen',
        path: '/isbn-kantoor/isbnAanvragen',
        key: ROUTE_KEY.ADMIN_ISBN_APPLICATIONS,
        component: isbnAdmin.scenes.IsbnApplicationOverview,
        requiredResources: ['isbnApplication'],
        subRoutes: [
          {
            name: 'Isbn aanvraag',
            path: '/isbn-kantoor/isbnAanvragen/:id',
            key: ROUTE_KEY.ADMIN_ISBN_APPLICATION,
            component: isbnAdmin.scenes.EditIsbnApplication,
            hideInNav: true,
          },
        ],
      },
      {
        name: 'Prefix aanvragen',
        path: '/isbn-kantoor/prefixAanvragen',
        key: ROUTE_KEY.ADMIN_PREFIX_APPLICATIONS,
        component: isbnAdmin.scenes.PrefixApplicationOverview,
        requiredResources: ['prefixApplication'],
        subRoutes: [
          {
            name: 'prefix aanvraag',
            path: '/isbn-kantoor/prefixAanvragen/:id',
            key: ROUTE_KEY.ADMIN_PREFIX_APPLICATION,
            component: isbnAdmin.scenes.editPrefixApplication,
            hideInNav: true,
          },
        ],
      },
      {
        name: 'Prefix overzicht',
        path: '/isbn-kantoor/prefixes',
        key: ROUTE_KEY.ADMIN_PREFIX,
        requiredResources: ['prefixes'],
        component: isbnAdmin.scenes.PrefixOverview,
        subRoutes: [
          {
            name: 'Prefix',
            path: '/isbn-kantoor/prefixes/:id',
            key: ROUTE_KEY.ADMIN_PREFIX_EDIT,
            component: isbnAdmin.scenes.EditPrefix,
            hideInNav: true,
          },
        ],
      },
      {
        name: 'Master prefixes overzicht',
        path: '/isbn-kantoor/masterPrefixes',
        key: ROUTE_KEY.ADMIN_MASTER_PREFIXES,
        requiredResources: ['masterPrefixes'],
        component: isbnAdmin.scenes.MasterPrefixOverview,
      },
    ],
  },
  {
    name: 'administratie',
    path: '/administratie',
    component: null,
    order: 8,
    key: ROUTE_KEY.ADMIN,
    subRoutes: [
      {
        name: 'organisaties',
        path: '/administratie/organisaties',
        component: organisationAdmin.scenes.Organisations,
        key: ROUTE_KEY.ADMIN_ORG,
        allowRemount: true,
        requiredResources: ['organisations'],
        subRoutes: [
          {
            name: 'organisatie aanmaken',
            path: '/administratie/organisaties/aanmaken',
            key: ROUTE_KEY.ADMIN_ORG_CREATE,
            component: organisationAdmin.scenes.Create,
          },
          {
            name: 'organisatie',
            path: '/administratie/organisatie/:id',
            component: organisationAdmin.scenes.Update,
            hideInNav: true,
            key: ROUTE_KEY.ADMIN_ORG_EDIT,
            subRoutes: [
              {
                name: 'lidmaatschap',
                path: '/administratie/organisatie/:id/lidmaatschap',
                component: organisationAdmin.scenes.Membership,
                key: ROUTE_KEY.ADMIN_ORG_MEMBER,
              },
              {
                name: 'gebruikers',
                path: '/administratie/organisatie/:id/gebruikers',
                component: organisationAdmin.scenes.Users,
                key: ROUTE_KEY.ADMIN_ORG_USERS,
              },
              {
                name: 'distributeur',
                path: '/administratie/organisatie/:id/distributeur',
                component: organisationAdmin.scenes.Distributor,
                key: ROUTE_KEY.ADMIN_ORG_DISTRIBUTOR,
              },
              {
                name: 'uitgever',
                path: '/administratie/organisatie/:id/uitgever',
                component: organisationAdmin.scenes.Publisher,
                key: ROUTE_KEY.ADMIN_ORG_PUBLISHER,
              },
              {
                name: 'data leverancier',
                path: '/administratie/organisatie/:id/data-leverancier',
                component: organisationAdmin.scenes.DataProducer,
                key: ROUTE_KEY.ADMIN_ORG_DATA_PRODUCER,
              },
              {
                name: 'data afnemer',
                path: '/administratie/organisatie/:id/data-afnemer',
                component: organisationAdmin.scenes.DataConsumer,
                key: ROUTE_KEY.ADMIN_ORG_DATA_CONSUMER,
              },
              {
                name: 'boekhandel',
                path: '/administratie/organisatie/:id/boekhandel',
                component: organisationAdmin.scenes.Bookstore,
                key: ROUTE_KEY.ADMIN_ORG_BOOKSTORE,
              },
              {
                name: 'bibliotheek',
                path: '/administratie/organisatie/:id/bibliotheek',
                component: organisationAdmin.scenes.Library,
                key: ROUTE_KEY.ADMIN_ORG_LIBRARY,
              },
            ],
          },
        ],
      },
      {
        name: 'fondscodes',
        path: '/administratie/fondscodes',
        key: ROUTE_KEY.FUNDS,
        requiredResources: ['fundsAdmin'],
        component: fundAdmin.scenes.FundsListPage,
        allowRemount: true,
        subRoutes: [
          {
            name: 'fondscode',
            path: '/administratie/fondscodes/aanmaken',
            component: fundAdmin.scenes.NewFundPage,
            hideInNav: true,
            key: ROUTE_KEY.FUNDS_CREATE,
            requiredResources: ['fundsAdmin'],
          },
          {
            name: 'fondscode',
            path: '/administratie/fondscode/:id',
            component: fundAdmin.scenes.UpdateFundPage,
            hideInNav: true,
            key: ROUTE_KEY.FUNDS_UPDATE,
            requiredResources: ['fundsAdmin'],
          },
        ],
      },
    ],
  },
  {
    name: 'fondscodes distributor',
    path: '/fondscodes',
    key: ROUTE_KEY.FUNDS,
    requiredResources: ['fundsDistributor'],
    component: fundAdmin.scenes.FundsListPage,
    allowRemount: true,
    subRoutes: [
      {
        name: 'fondscode',
        path: '/fondscodes/aanmaken',
        component: fundAdmin.scenes.NewFundPage,
        hideInNav: true,
        key: ROUTE_KEY.FUNDS_CREATE,
      },
      {
        name: 'fondscode',
        path: '/fondscode/:id',
        component: fundAdmin.scenes.UpdateFundPage,
        hideInNav: true,
        key: ROUTE_KEY.FUNDS_UPDATE,
      },
    ],
  },
  {
    name: 'retouraanvraag',
    path: '/retour',
    order: 10,
    hideInNav: false,
    component: RetourRequestPage,
    key: ROUTE_KEY.RETURN_REQUEST,
    requiredResources: ['retours'],
    subRoutes: [
      {
        name: 'Retours',
        path: '/retour/historiek',
        component: RetourLinesHistoryPage,
        key: ROUTE_KEY.RETURN_HISTORY,
        requiredResources: ['retours'],
        subRoutes: [
          {
            name: 'retour detail',
            path: '/retour/historiek/retours',
            hideInNav: true,
            key: ROUTE_KEY.RETURN_HISTORY_RETURNS,
            component: RetourHistoryPage,
          },
          {
            name: 'retour detail',
            path: '/retours/:id',
            hideInNav: true,
            key: ROUTE_KEY.RETURN_DETAIL,
            component: RetourDetailPage,
          },
        ],
      },
    ],
  },
  {
    name: 'uitgeverslijst',
    path: '/uitgeverslijst',
    order: 12,
    component: publisher.scenes.PublishersListPage,
    key: ROUTE_KEY.ADMIN_ORG_PUBLISHERS,
    allowRemount: true,
    requiredResources: ['publisherSearch'],
  },
  {
    //data-consumer may only view the list of fonds codes. No subroutes for editing or creating.
    name: 'fondscodes data-consumer',
    path: '/fondscodes',
    key: ROUTE_KEY.FUNDS_VIEW,
    requiredResources: ['fundsDataConsumer'],
    component: fundAdmin.scenes.FundsListPage,
    allowRemount: true,
  },
  {
    name: 'boekhandels',
    path: '/bookstores',
    component: bookstore.scenes.BookstoresListPage,
    key: ROUTE_KEY.ADMIN_ORG_BOOKSTORES,
    requiredResources: ['bookstoreSearch'],
  },
  {
    name: 'help',
    path: '/help',
    hideInNav: true,
    key: ROUTE_KEY.HELP,
    component: null,
    requiredResources: ['titles'],
    subRoutes: [
      {
        name: 'contact',
        path: '/contact',
        hideInNav: true,
        key: ROUTE_KEY.CONTACT,
        component: ContactPage,
      },
      {
        name: 'faq',
        path: '/faq',
        hideInNav: true,
        key: ROUTE_KEY.FAQ,
        component: FAQPage,
      },
    ],
  },
];

import { Grid, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Button from '../../../components/Button';
import { Funds, TitleFull } from '../../../config/api/types';
import useApiRoute from '../../../config/api/useApiRoute';
import useFetch from '../../../helpers/useFetch';
import { getFundsRequest } from '../../title/admin/title/api';
import { mapTitleDTO } from '../../title/admin/title/dataMapper';
import IsbnTitleForm from '../components/IsbnTitleForm';
import { IsbnSteppedFormProps } from '../types';
import { SearchTitleDialog } from './searchTitle/SearchTitleDialog';

const useStyles = makeStyles((theme: Theme) => ({
  formContainer: {
    display: 'flex',
  },
}));

const TitleStep = ({
  submitting,
  values,
  submitErrors,
  changeStep,
  form: { change, initialize },
  user,
  errors,
  touched,
}: IsbnSteppedFormProps) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [modalOpen, setModalOpen] = useState<boolean>(false);

  const fundsRoute = useApiRoute(['fundsDistributor', 'fundsAdmin', 'fundsBookstore']);
  const [fundsRequest, fetchFunds] = useFetch<Funds>(getFundsRequest());

  useEffect(() => {
    if (fundsRoute && !fundsRequest?.value && !fundsRequest?.pending) {
      fetchFunds(fundsRoute);
    }
  }, [fundsRoute, fundsRequest, fetchFunds]);

  useEffect(() => {
    if (fundsRoute && user && fundsRequest?.value && !values.fund) {
      const defaultFund = fundsRequest.value._embedded.items.find(
        (fund) =>
          fund.isDefault &&
          fund.organisation?.meta4BooksId === user._embedded?.organisation?.meta4BooksId
      );
      if (defaultFund) {
        change('fund', defaultFund);
      }
    }
  }, [fundsRequest, fundsRoute, user, values.fund, change]);

  const applyTitleToForm = (title: TitleFull) => {
    setModalOpen(false);
    initialize((data) => ({
      ...data,
      ...mapTitleDTO(title).toFormValues(),
      availability: undefined,
      selectedSupplyDate: undefined,
      orderTime: undefined,
      fund: undefined,
      price: undefined,
      priceDescription: undefined,
      discountCode: undefined,
      productForm: undefined,
      priceAction: undefined,
      priceRegulated: undefined,
      priceRegulatedAction: undefined,
      hasInformationRestriction: false,
      hasOrderRestriction: false,
      replacedBy: undefined,
      replacementOf: undefined,
      legalDepotNumber: undefined,
      mediaFrontCover: undefined,
      mediaBackCover: undefined,
      mediaSample: undefined,
      isBibliographicalVerified: false,
    }));
  };

  const actionButtons = (
    <Grid container item sx={{ justifyContent: 'space-between' }}>
      <Grid item>
        <Button onClick={() => changeStep(-1)} variant="outlined" disabled={submitting}>
          {t('form_previous')}
        </Button>
      </Grid>
      <Grid item>
        <Button type="submit" disabled={submitting}>
          {t('form_next')}
        </Button>
      </Grid>
    </Grid>
  );

  return (
    <>
      <div className={classes.formContainer}>
        <IsbnTitleForm
          values={values}
          submitErrors={submitErrors}
          actionButtons={actionButtons}
          change={change}
          newTitleForm
          isAuthenticated={Boolean(user)}
          validationErrors={errors}
          touched={touched}
          onClickFetchFromExistingIsbn={() => setModalOpen(true)}
        />
      </div>

      <SearchTitleDialog
        isOpen={modalOpen}
        onClose={() => setModalOpen(false)}
        onSelectTitle={applyTitleToForm}
      />
    </>
  );
};

export default TitleStep;
